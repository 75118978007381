<template>
  <div class="xmsbxq">
    <div class="header">
      <div class="h1">{{ul[activeName].text}}</div>
      <div class="h2">智能匹配   一键办理</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item >政策服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/xmsbxq' }">详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="xmsbxqcontent">
<!--        <div class="tabs flex">-->
<!--          <div class="flex-1 span" v-for="(li,index) in ul " @click="tabc(li,index)"><span :class="{active:activeName==index}">{{li.text}}</span></div>-->
<!--        </div>-->
        <div class="actor" v-if="activeName==0">
          <div class="news">
            <div class="title"><i class="yuan"></i>{{datas.title}}</div>
            <div class="biao">
              <span class="biaoqian" v-for="(bi,index) in datas.labels?datas.labels.split(','):''">{{bi}}</span>
            </div>
            <div class="status">
              <span class="st1">{{datas.state}}</span>
              <el-button type="primary" @click="goyuan">查看原文</el-button>
            </div>
            <div class="di">
              <div class="">管理部门:{{datas.createDeptName}}</div>
              <div class="">申报起止时间:{{datas.startTime}}至{{datas.endTime}}</div>
            </div>
          </div>
          <el-descriptions title="" :column="2" border>
            <el-descriptions-item label="申报起止时间">{{ datas.startTime }}至{{datas.endTime}}</el-descriptions-item>
            <el-descriptions-item label="管理科室">{{datas.department}}</el-descriptions-item>
            <el-descriptions-item label="联系人">{{datas.contactUser}}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{datas.contactPhone}}</el-descriptions-item>
            <el-descriptions-item label="政策标题" span="2" >{{datas.policyTitle}}</el-descriptions-item>
            <el-descriptions-item label="申报对象和条件"  span="2" >{{datas.declarationCondition}}</el-descriptions-item>
            <el-descriptions-item label="申请书样例" span="2"  > <fujian :files="datas.example"></fujian></el-descriptions-item>
            <el-descriptions-item label="操作规程" span="2"  ><fujian :files="datas.process"></fujian></el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="actor" v-else-if="activeName==1">
          <div class="h1"> {{datas.title}}</div>
          <div class="h2"> 发布时间：{{datas.createTime}}     <span style="margin-left: 20px">来源：{{datas.source}}</span></div>
          <div class="ccontent" v-html="datas.content"></div>
          <fujian style="margin-top: 50px" :files="datas.accessorySet" v-if="datas.accessorySet" :showtitle="true"></fujian>
        </div>
        <div class="actor"v-else>
          <div class="h1"> {{datas.title}}</div>
          <div class="h2"> 发布时间：{{datas.createTime}}     <span style="margin-left: 20px">来源：{{datas.source}}</span></div>
          <div class="ccontent" v-html="datas.content"></div>
          <fujian style="margin-top: 50px" :files="datas.accessorySet" v-if="datas.accessorySet" :showtitle="true"></fujian>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {policydetail, policyprojectdetail, policyunscrambledetail} from "../../request/moudle/zcfw";
import {filesub} from '../../utils/index'
import fujian from "../common/fujian.vue";
import {parkpolicydetail} from "../../request/moudle/park";
export default {
  name: "gonggaoxq",
  components: {fujian},
  data(){
    return {
      filesub:filesub,
      activeName:'0',
      datas:'',
      ul:[{text:'项目申报'},{text:'政策原文'},{text:'政策解答'}],
      arr:[{title:'财政部 税务总局关于继续对废矿物油再生油品免征消费税的公告（2023年第69号）',peo:'工信局',time:'2021-12-15',
        status:'剩余：9天',
        type:[{text:'项目标签'},{text:'项目标签'}]}],
    }
  },
  created() {
    this.activeName=this.$route.query.activeName || '0'
    this.tabs()
  },
  methods:{
    // tabc(li,index){
    //   this.activeName=index
    //   this.tabs()
    // },
    goyuan(){
      this.activeName=1
      this.$route.query.id=this.datas.policyId
      this.tabs()
    },
    tabs(){
      if(this.activeName==1){
        this.loadlist()
      }else if(this.activeName==2){
        this.loadlist1()
      }else {
        this.loadlist2()
      }
    },
    loadlist(){
      this.$api.park.parkpolicydetail({
        id:this.$route.query.id
      }).then((res)=>{
          this.datas=res.data
      })
    },
    loadlist1(){
      this.$api.park.policyunscrambledetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas=res.data
      })
    },
    loadlist2(){
      this.$api.park.policyprojectdetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .ccontent{
      display: inline-block;
    }
    .fujian{
      margin-top: 50px;
      .as{
        line-height: 25px;
        color:  #409EFF;
      }
    }
  .xmsbxq{
     background: #F5F5F5;
    .header{
      height: 120px;
      background: url("../../assets/gxjy/gxjy.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1{
        font-size: 42px;
        font-weight: bolder;
      }
      .h2{
        font-size: 22px;
        line-height: 80px;
      }
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .xmsbxqcontent{
          background: #fff;
          padding: 20px;
          margin-top: 20px;
        .tabs {
          border-bottom: 1px #D7D7D7 solid;
          text-align: center;
            span{
              font-weight: 550;
              padding:10px 22px;
              line-height: 39px;
            }
          .active{
            background:#0078F2;
            color: #fff;
          }
        }
        .actor{
          padding: 20px;
          .h1{
            font-weight: bolder;
            font-size: 28px;
            text-align: center;
          }
          .h2{
            text-align: center;
            line-height: 45px;
          }
          p{
            padding: 10px 0;
          }
          .news{
            background:linear-gradient(0deg,#FFFFFF,#EEF1F5);
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin-bottom: 55px;
            padding: 20px;
            color: #3B3E45;
            .title{
              font-size: 18px;
              margin-bottom: 10px;
            }
            .biao{
              padding-left: 27px;
              color: #fff;
              margin: 20px 0 0px 0;
            }
            .status{
              text-align: right;
              margin-right: 30px;
              font-size: 16px;
              color: red;
            }
            .st1{
              margin-right: 40px;
            }
            .biaoqian{
              background:linear-gradient(0deg,#0078F2,#00BAFF);
              padding: 2px 8px;
              margin-right: 10px;
            }
            .yuan{
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: #A5ACB9;
              margin-right: 15px;
            }
            .di{
              font-size: 14px;
              padding-left: 27px;
              color: #6D6D6D;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
</style>